/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {SearchResultEntry, SearchResultType} from '@dv/shared/code';
import type {Observable} from 'rxjs';
import type {MandantSearchFilter} from '../model/MandantSearchFilter';
import type {ArchiveSearchMode} from '../model/SearchArchivalProperty';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export abstract class SearchService {
    public abstract getEntityHrefArgs(entityName: SearchResultType, id: string, subRoute?: string): {
        to: string;
        params: { [index: string]: string };
    };

    public abstract transformSource(entry: SearchResultEntry, source: unknown): unknown;

    public abstract setSearchEntities(entities: string): void;

    public abstract getSearchEntities(): string | null;

    public abstract setSearchInAllMandanten(value: boolean): void;

    public abstract getSearchInAllMandanten(): boolean;

    public abstract searchGlobal$(
        searchText: string,
        findAll?: boolean,
        archiveMode?: ArchiveSearchMode,
    ): Observable<SearchResultEntry[]>;

    public abstract redirectToEntity(
        entityName: SearchResultType,
        id: string,
        openInNewWindow?: boolean,
        subRoute?: string,
    ): void;

    public abstract getEntityHref(
        entityName: SearchResultType,
        id: string,
        subRoute?: string,
    ): string;

    public abstract searchEntity$(
        searchText: string,
        params: {
            entities: string;
            expandEntity: boolean;
        },
        mandantFilter?: MandantSearchFilter,
    ): Observable<SearchResultEntry[]>;
}
