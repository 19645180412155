<tr ngForm #detailForm="ngForm" class="row-day-detail">
    <td colspan="2">
        @if (forNewDay()) {
            <dvlib-datepicker-textfield
                name="date"
                [(ngModel)]="state().date"
                class="small-abstand-bottom"
                required
                [showTodayButton]="false"
                [placeholder]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.DATE' | transloco"
                [datesEnabled]="availableDates()"/>
        }
        <textarea type="text"
                  [rows]="5"
                  [(ngModel)]="state().note"
                  [dvlibRequirePermission]="'work_time_controlling:work_time_controlling_table_edit:general'"
                  class="form-control"
                  name="note"
                  dvlibElasticTextInput
                  [placeholder]="'NOTIZ.PLURAL' | transloco"
                  [attr.aria-label]="'NOTIZ.PLURAL' | transloco"
                  [tooltip]="'NOTIZ.PLURAL' | transloco">
        </textarea>
    </td>

    <!-- Geplante Arbeitszeit -->
    <td>
        @for (plannedTime of plannedTimes(); track plannedTime) {
            <div class="work-time-controlling-card small-abstand-bottom"
                 [ngClass]="plannedTime.type === 'TERMIN' ? 'termin' : 'worktime'">
                <div class="time small-abstand-bottom">
                    <div>{{ plannedTime.timeRange.von | dvTimeFormat }}</div>
                    <div>{{ plannedTime.timeRange.bis | dvTimeFormat }}</div>
                </div>
                <div class="standort-readonly">
                    <span class="standort-content">
                        {{ plannedTime | displayName }}
                    </span>
                </div>
            </div>
        }
    </td>

    <!-- Ist Arbeitszeit -->
    <td>
        @for (actualTimeRange of state().actualTimes(); track actualTimeRange.uiId; let index = $index) {
            <div class="work-time-controlling-card small-abstand-bottom">
                @if (actualTimeRange.editPermitted) {
                    <button dvlibButton="link-button"
                            class="close-button"
                            [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_IST' | transloco"
                            [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_IST' | transloco"
                            (click)="removeTimeRange(actualTimeRange)">
                        <i class="custom-icon custom-icon-close-thin"></i>
                    </button>
                }
                <div class="time-inputs small-abstand-bottom">
                    <input type="time"
                           [name]="'von_' + index"
                           [disabled]="!actualTimeRange.editPermitted"
                           [(ngModel)]="actualTimeRange.timeRange.von"
                           validVon
                           [dateStart]="state().dateMoment()"
                           [dateEnd]="state().dateMoment()"
                           [timeEnd]="actualTimeRange.timeRange.bis"
                           required
                           class="form-control input-sm no-margin"/>
                    <input type="time"
                           [name]="'bis_' + index"
                           [disabled]="!actualTimeRange.editPermitted"
                           [(ngModel)]="actualTimeRange.timeRange.bis"
                           validBis
                           [timeStart]="actualTimeRange.timeRange.von"
                           [dateStart]="state().dateMoment()"
                           [dateEnd]="state().dateMoment()"
                           required
                           class="form-control input-sm no-margin"/>
                </div>
                <button dvlibButton="link-button"
                        class="standort-selection"
                        [disabled]="!actualTimeRange.editPermitted"
                        dvOverlay
                        [overlayTemplate]="selectStandortForTime"
                        [overlayContext]="{$implicit: actualTimeRange}"
                        [overlayOpen]="actualTimeRange.overlayOpen()"
                        (closeOverlay)="actualTimeRange.overlayOpen.set(false)"
                        (click)="actualTimeRange.overlayOpen.set(true)">
                    <span class="button-content">
                        @if (actualTimeRange.kinderOrt) {
                            <span>{{ actualTimeRange.kinderOrt.text }}</span>
                        }
                        @if (actualTimeRange.kinderOrt && actualTimeRange.kinderOrtFraktion) {
                            <span>, </span>
                        }
                        @if (actualTimeRange.kinderOrtFraktion) {
                            <span>{{ actualTimeRange.kinderOrtFraktion.text }}</span>
                        }

                        @if (!actualTimeRange.kinderOrt && !actualTimeRange.kinderOrtFraktion) {
                            + <span transloco="WORK_TIME_CONTROLLING.WORK_TIME_TABLE.SELECT_LOCATION"></span>
                        }
                    </span>
                </button>
            </div>
        }
        <div class="row" *dvlibRequirePermission="'work_time_controlling:work_time_controlling_table_edit:general'">
            <div class="col-md-12 text-center">
                <button dvlibButton="link-button"
                        [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_IST' | transloco"
                        [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_IST' | transloco"
                        (click)="addWorkDay()">
                    <i class="custom-icon custom-icon-plus-thin"></i>
                </button>
            </div>
        </div>
    </td>

    <!-- Absenzen -->
    <td>
        @for (absence of state().absences(); track absence.uiId; let absenceIndex = $index) {
            <div class="work-time-controlling-card small-abstand-bottom">
                @if (absence.editPermitted) {
                    <button dvlibButton="link-button"
                            class="close-button"
                            [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_ABSENCE' | transloco"
                            [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_ABSENCE' | transloco"
                            (click)="removeAbsence(absence)">
                        <i class="custom-icon custom-icon-close-thin"></i>
                    </button>
                }
                <div class="time-inputs small-abstand-bottom">
                    <input type="time"
                           [name]="'absence_von_' + absenceIndex"
                           [disabled]="!absence.editPermitted"
                           [(ngModel)]="absence.timeRange.von"
                           (ngModelChange)="updateAbsenceMinutes(absence)"
                           validVon
                           [dateStart]="state().dateMoment()"
                           [dateEnd]="state().dateMoment()"
                           [timeEnd]="absence.timeRange.bis"
                           [required]="!!absence.timeRange.bis" class="form-control input-sm no-margin"/>
                    <input type="time"
                           [name]="'absence_bis_' + absenceIndex"
                           [disabled]="!absence.editPermitted"
                           [(ngModel)]="absence.timeRange.bis"
                           (ngModelChange)="updateAbsenceMinutes(absence)"
                           validBis
                           [timeStart]="absence.timeRange.von"
                           [dateStart]="state().dateMoment()"
                           [dateEnd]="state().dateMoment()"
                           [required]="!!absence.timeRange.von" class="form-control input-sm no-margin"/>
                </div>
                <div class="time-inputs small-abstand-bottom">
                    <p class="input-spacer">{{ 'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.DURATION' | transloco }}</p>
                    <input type="text"
                           dvMinutesInput
                           placeholder="HH:mm"
                           [name]="'duration_' + absenceIndex"
                           [disabled]="!absence.editPermitted"
                           [(ngModel)]="absence.minutes"
                           [required]="!absence.timeRange.von && !absence.timeRange.bis"
                           class="form-control input-sm no-margin"/>
                </div>
                <div class="row small-abstand-bottom">
                    <div class="col-md-12">
                        <select [name]="'absenceType_' + absenceIndex"
                                [disabled]="!absence.editPermitted"
                                class="form-control no-margin input-sm"
                                [(ngModel)]="absence.absenceTypeId" required
                                [tooltip]="'PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE' | transloco">
                            <option [ngValue]="undefined" disabled>{{ 'PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE' | transloco }}</option>
                            @for (type of absenceTypes(); track type.id) {
                                <option [ngValue]="type.id">{{ type.name }}</option>
                            }
                        </select>
                    </div>
                </div>
                <button dvlibButton="link-button"
                        class="standort-selection"
                        [disabled]="!absence.editPermitted"
                        dvOverlay
                        [overlayTemplate]="selectStandortForAbsence"
                        [overlayContext]="{$implicit: absence}"
                        [overlayOpen]="absence.overlayOpen()"
                        (closeOverlay)="absence.overlayOpen.set(false)"
                        (click)="absence.overlayOpen.set(true)">
                    <span class="button-content">
                        @if (absence.kinderOrt) {
                            {{ absence.kinderOrt.text }}
                        } @else {
                            + <span transloco="WORK_TIME_CONTROLLING.WORK_TIME_TABLE.SELECT_LOCATION"></span>
                        }
                    </span>
                </button>
            </div>
        }
        <div class="row" *dvlibRequirePermission="'work_time_controlling:work_time_controlling_table_edit:general'">
            <div class="col-md-12 text-center">
                <button dvlibButton="link-button"
                        [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_ABSENCE' | transloco"
                        [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.ADD_ABSENCE' | transloco"
                        (click)="addAbsence()">
                    <i class="custom-icon custom-icon-plus-thin"></i>
                </button>
            </div>
        </div>
    </td>
    <td>
        @if (state().isBalanceCorrectionVisible()) {
            <div class="work-time-controlling-card">
                <button *dvlibRequirePermission="'work_time_controlling:balance_correction:general'"
                        dvlibButton="link-button"
                        class="close-button"
                        [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_CORRECTION' | transloco"
                        [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_CORRECTION' | transloco"
                        (click)="removeCorrection()">
                    <i class="custom-icon custom-icon-close-thin"></i>
                </button>
                <div class="correction">
                    <span class="floating-text">{{ 'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.CORRECTION' | transloco }}</span>
                    <input type="text"
                           class="form-control input-sm no-margin"
                           dvMinutesInput
                           placeholder="HH:mm"
                           required
                           [name]="'balance_correction'"
                           [dvlibRequirePermission]="'work_time_controlling:balance_correction:general'"
                           [(ngModel)]="state().balanceCorrection"
                           [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.BALANCE_CORRECTION' | transloco"/>
                </div>
            </div>
        } @else {
            <button *dvlibRequirePermission="'work_time_controlling:balance_correction:general'"
                    class="btn btn-link add-correction"
                    (click)="state().isBalanceCorrectionVisible.set(true)">
                + {{ 'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.BALANCE_CORRECTION' | transloco }}
            </button>
        }
    </td>
    <td>
        @if (state().isBalanceCorrectionHolidaysVisible()) {
            <div class="work-time-controlling-card">
                <button *dvlibRequirePermission="'work_time_controlling:balance_correction:general'"
                        dvlibButton="link-button"
                        class="close-button"
                        [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_CORRECTION' | transloco"
                        [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.REMOVE_CORRECTION' | transloco"
                        (click)="removeCorrectionHolidays()">
                    <i class="custom-icon custom-icon-close-thin"></i>
                </button>
                <div class="correction">
                    <span class="floating-text">{{ 'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.CORRECTION' | transloco }}</span>
                    <input type="text"
                           class="form-control input-sm no-margin"
                           dvMinutesInput
                           placeholder="HH:mm"
                           required
                           [name]="'balance_correction_holidays'"
                           [dvlibRequirePermission]="'work_time_controlling:balance_correction:general'"
                           [(ngModel)]="state().balanceCorrectionHolidays"
                           [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.BALANCE_CORRECTION_HOLIDAYS' | transloco"/>
                </div>
            </div>
        } @else {
            <button *dvlibRequirePermission="'work_time_controlling:balance_correction:general'"
                    class="btn btn-link add-correction"
                    (click)="state().isBalanceCorrectionHolidaysVisible.set(true)">
                + {{ 'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.BALANCE_CORRECTION_HOLIDAYS' | transloco }}
            </button>
        }
    </td>
</tr>
<tr class="row-day-detail" *dvlibRequirePermission="'work_time_controlling:work_time_controlling_table_edit:general'">
    <td>
        <dvlib-button-list>
            <button dvlibButton="primary" (click)="submit($event, detailForm)">
                {{ 'COMMON.SPEICHERN' | transloco }}
            </button>
            @if (forNewDay()) {
                <button dvlibButton="link-button" (click)="abort.emit()">
                    {{ 'COMMON.ABBRECHEN' | transloco }}
                </button>
            } @else {
                <button dvlibButton="link-button" (click)="resetChanges()">
                    {{ 'COMMON.RESET' | transloco }}
                </button>
            }
        </dvlib-button-list>
    </td>
</tr>

<ng-template #selectStandortForAbsence let-model="$implicit">
    <div class="overlay-selection">
        <dv-search-entity [entityToSearch]="'KITA'"
                          [(ngModel)]="model.kinderOrt"
                          [placeholder]="'COMMON.KINDERORT.AUSWAEHLEN' | transloco">
        </dv-search-entity>
    </div>
</ng-template>

<ng-template #selectStandortForTime let-model="$implicit">
    <div class="overlay-selection">
        <dv-search-entity [entityToSearch]="'KITA'"
                          [(ngModel)]="model.kinderOrt"
                          [placeholder]="'COMMON.KINDERORT.AUSWAEHLEN' | transloco"
                          class="smaller-abstand-bottom">
        </dv-search-entity>
        <dv-search-entity [entityToSearch]="'GRUPPE'"
                          [(ngModel)]="model.kinderOrtFraktion"
                          [placeholder]="'COMMON.GRUPPE.AUSWAEHLEN' | transloco">
        </dv-search-entity>
    </div>
</ng-template>
