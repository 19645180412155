/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';
import type {ParamTypeDefinition} from '@uirouter/core';
import type moment from 'moment';
import {UrlParameterTypes} from './urlParameterTypes';

export class MomentDataParameter implements ParamTypeDefinition {

    public static type: string = UrlParameterTypes.MOMENT_DATE;

    public pattern: RegExp = /\d{4}-\d{2}-\d{2}/;

    public constructor() {
        this.decode = this.decode.bind(this);
        this.encode = this.encode.bind(this);
        this.equals = this.equals.bind(this);
        this.is = this.is.bind(this);
    }

    public decode(val: string | null, _key?: string): moment.Moment | null {
        return DvbRestUtil.localDateToMoment(val ?? '');
    }

    public encode(val: unknown, _key?: string): string | string[] {
        return DvbRestUtil.momentToLocalDate(val) ?? '';
    }

    public equals(a: unknown, b: unknown): boolean {
        return DvbDateUtil.isMomentEquals(a, b);
    }

    public is(val: unknown, _key?: string): boolean {
        return DvbDateUtil.isValidMoment(val);
    }
}
