import {withDevtools} from '@angular-architects/ngrx-toolkit';
import {computed, inject} from '@angular/core';
import {withAccordionFunctionality} from '@dv/kitadmin/ui';
import {handleResponse, idFromLocation} from '@dv/shared/angular';
import {
    WorkTimeControllingAbsenceTypesService,
} from '@dv/shared/backend/api/work-time-controlling-absence-types.service';
import type {JaxWorkTimeControllingAbsenceType} from '@dv/shared/backend/model/jax-work-time-controlling-absence-type';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted, checkPresent} from '@dv/shared/code';
import {patchState, signalStore, withComputed, withHooks, withMethods, withState} from '@ngrx/signals';
import {removeEntity} from '@ngrx/signals/entities';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import {concatMap, map, pipe, switchMap, tap} from 'rxjs';

type ModelsState = {
    isCreating: boolean;
    createdTypeId: string;
};

const initialState: ModelsState = {
    isCreating: false,
    createdTypeId: '',
};

export const AbsenceTypesStore = signalStore(
    withDevtools('absenceTypes'),
    withState(initialState),
    withAccordionFunctionality<Persisted<JaxWorkTimeControllingAbsenceType>>(),
    withComputed(store => ({
        isLoadingOrCreating: computed<boolean>(() => {
            const loading = store.isLoading();
            const creating = store.isCreating();

            return loading || creating;
        }),
    })),
    withMethods((
        store,
        service = inject(WorkTimeControllingAbsenceTypesService),
    ) => ({
        load: rxMethod<void>(pipe(
            tap(() => store.startLoading()),
            switchMap(() => service.getAllTypes$().pipe(
                map(data => data.types.map(checkPersisted)
                    .sort((a, b) => a.name.localeCompare(b.name))),
                handleResponse({
                    next: types => store.initWithEntities(types),
                    finalize: () => store.finishLoading(),
                }),
            )),
        )),
        createType: rxMethod<JaxWorkTimeControllingAbsenceType>(pipe(
            tap(() => patchState(store, {isCreating: true})),
            concatMap(jaxWorkTimeControllingAbsenceType =>
                service.createType$({jaxWorkTimeControllingAbsenceType}, 'response')
                    .pipe(
                        handleResponse({
                            next: response => {
                                const id = idFromLocation(response);
                                patchState(store, {createdTypeId: id});
                                store.add({id, ...jaxWorkTimeControllingAbsenceType});
                                store.disableCreateMode();
                            },
                            finalize: () => patchState(store, {isCreating: false}),
                        }),
                    ),
            ),
        )),
        updateType: rxMethod<JaxWorkTimeControllingAbsenceType>(pipe(
            tap(model => store.setItemIsLoadingTrue(checkPresent(model.id))),
            concatMap(type =>
                service.updateType$({id: type.id!, jaxWorkTimeControllingAbsenceType: type}).pipe(
                    handleResponse({
                        next: () => store.update({...store.entityMap()[type.id!].entity, ...type}),
                        finalize: () => store.setItemIsLoadingFalse(type.id!),
                    }),
                )),
        )),
        confirmDelete: rxMethod<string>(pipe(
            tap(() => patchState(store, {isLoading: true})),
            concatMap(id => service.deleteType$({id}).pipe(
                handleResponse({
                    next: () => {
                        patchState(store, removeEntity(id));
                        store.hideDeleteDialog();
                    },
                    finalize: () => patchState(store, {isLoading: false}),
                }),
            )),
        )),
    })),
    withHooks({
        onInit(store) {

            // reload whenever a new model got created
            store.load(store.createdTypeId);
        },
    }),
);
