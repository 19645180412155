/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {computed, inject} from '@angular/core';
import {AuthStore, handleResponse} from '@dv/shared/angular';
import {
    WorkTimeControllingAbsenceTypesService,
} from '@dv/shared/backend/api/work-time-controlling-absence-types.service';
import type {
    WorkTimeControllingServiceGetDaysForAngestellteRequestParams,
} from '@dv/shared/backend/api/work-time-controlling.service';
import {WorkTimeControllingService} from '@dv/shared/backend/api/work-time-controlling.service';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {JaxWorkTimeControllingAbsenceType} from '@dv/shared/backend/model/jax-work-time-controlling-absence-type';
import type {Displayable} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, nameComparator} from '@dv/shared/code';
import {patchState, signalStoreFeature, type, withComputed, withHooks, withMethods, withState} from '@ngrx/signals';
import {setEntity, updateEntity, withEntities} from '@ngrx/signals/entities';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import type moment from 'moment';
import {map, pipe, switchMap, tap} from 'rxjs';
import type {
    SortBy,
    SortOrder,
    WorkTimeControllingTableData,
} from '../work-time-controlling-table/work-time-controlling-table.models';
import {createWorkTimeControllingTableData} from '../work-time-controlling-table/work-time-controlling-table.models';

type State = {
    isLoading: boolean;
    selectedMonth: moment.Moment;
    sortOrder: SortOrder;
    sortBy: SortBy;
    isLoadingAbsenceTypes: boolean;
    absenceTypes: JaxWorkTimeControllingAbsenceType[];
};

const initialState: State = {
    isLoading: false,
    selectedMonth: DvbDateUtil.startOfMonth(DvbDateUtil.today()),
    sortOrder: 'asc',
    sortBy: 'displayname',
    isLoadingAbsenceTypes: false,
    absenceTypes: [],
};

// suppress function return type warning, because we want the complex return type to be inferred
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withWorkTimeControllingTable() {

    return signalStoreFeature(
        withEntities<WorkTimeControllingTableData>(),
        withEntities({
            entity: type<Displayable>(),
            collection: 'kinderOrte',
        }),
        withEntities({
            entity: type<Displayable>(),
            collection: 'kinderOrtFraktionen',
        }),
        withState<State>(initialState),
        withComputed(
            store => ({
                sortedData: computed<WorkTimeControllingTableData[]>(() => {
                    const entities = store.entities();
                    const sortBy = store.sortBy();
                    const sortOrder = store.sortOrder() === 'asc' ? 1 : -1;

                    return entities.slice().sort((a, b) => {
                        if (sortBy === 'displayname') {
                            return a.angestellteDisplayName.localeCompare(b.angestellteDisplayName) * sortOrder;
                        }

                        return (a[sortBy] - b[sortBy]) * sortOrder;
                    });
                }),
            }),
        ),
        withMethods((
            store,
            service = inject(WorkTimeControllingService),
            authStore = inject(AuthStore),
            absenceTypeService: WorkTimeControllingAbsenceTypesService = inject(WorkTimeControllingAbsenceTypesService),
        ) => ({
            startLoading() {
                patchState(store, {isLoading: true});
            },
            applyMonth(month: moment.Moment) {
                patchState(store, {selectedMonth: month});
            },
            toggleSort(sortBy: SortBy) {
                patchState(store, {
                    sortBy,
                    sortOrder: store.sortBy() === sortBy && store.sortOrder() === 'asc' ? 'desc' : 'asc',
                });
            },
            toggleAngestellte(angestellteId: string) {
                const current = store.entityMap()[angestellteId];
                patchState(store, updateEntity({id: angestellteId, changes: {expanded: !current.expanded}}));
            },
            toggleDay(angestellteId: string, date: BackendLocalDate) {
                const current = store.entityMap()[angestellteId];
                const dailyHours = current.dailyHours.slice();
                const dateIndex = dailyHours.findIndex(daily => daily.date === date);
                if (dateIndex < 0) {
                    return;
                }
                dailyHours[dateIndex] = {...dailyHours[dateIndex], expanded: !dailyHours[dateIndex].expanded};

                patchState(store, updateEntity({id: angestellteId, changes: {dailyHours}}));
            },
            loadDaysForMandant: rxMethod<WorkTimeControllingServiceGetDaysForAngestellteRequestParams>(pipe(
                tap(() => patchState(store, {isLoading: true})),
                switchMap(payload => service.getDaysForAngestellte$(payload).pipe(
                    handleResponse({
                        next: data => {
                            const wtcData: WorkTimeControllingTableData =
                                createWorkTimeControllingTableData([data], authStore, store.selectedMonth())[0];

                            const existingState = store.entityMap()[payload.angestellteId];
                            const stichtag = payload.angestellteIdMatrix.stichtag;
                            const updateDailyHour = wtcData.dailyHours.find(newDh => newDh.date === stichtag);

                            const writeState: WorkTimeControllingTableData = {
                                ...existingState,
                                // only update the dailyHours that we reloaded. Don't change other UI state
                                // Overriding a specific date is bad enough (destroying form state, see KIT-7434).
                                dailyHours: existingState.dailyHours.map(dh => {
                                    return dh.date === stichtag ?
                                        {...checkPresent(updateDailyHour), expanded: dh.expanded} :
                                        dh;
                                }),
                                // these "computed" values must update
                                expectedMinutes: wtcData.expectedMinutes,
                                plannedMinutes: wtcData.plannedMinutes,
                                actualMinutes: wtcData.actualMinutes,
                                absenceMinutes: wtcData.absenceMinutes,
                                timeBalance: wtcData.timeBalance,
                                holidayBalance: wtcData.holidayBalance,
                                warning: wtcData.warning,
                            };

                            patchState(store, setEntity(writeState));
                        },
                        finalize: () => patchState(store, {isLoading: false}),
                    })),
                ),
            )),
            addTempDay(angestellteId: string) {
                patchState(store, updateEntity({
                    id: angestellteId, changes: {
                        tempDailyHours: {
                            date: '',
                            note: '',
                            expectedMinutes: 0,
                            plannedMinutes: 0,
                            actualMinutes: 0,
                            absenceMinutes: 0,
                            timeBalance: 0,
                            holidayBalance: 0,
                            expanded: false,
                            warning: undefined,
                            standortWorkDays: [],
                            absences: [],
                            absenceTermine: [],
                            balanceCorrection: null,
                            balanceCorrectionHolidays: null,
                        },
                    },
                }));
            },
            removeTempDay(angestellteId: string) {
                patchState(store, updateEntity({id: angestellteId, changes: {tempDailyHours: undefined}}));
            },
            loadAbsenceTypes: rxMethod<void>(pipe(
                tap(() => patchState(store, {isLoadingAbsenceTypes: true})),
                switchMap(() => absenceTypeService.getAllTypes$().pipe(
                    map(data => data.types.sort(nameComparator)),
                    handleResponse({
                        next: absenceTypes => {
                            patchState(store, {absenceTypes});
                        },
                    })),
                )),
            ),
        })),
        withHooks({
            onInit(store) {
                store.loadAbsenceTypes();
            },
        }),
    );
}
