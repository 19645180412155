/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {withDevtools} from '@angular-architects/ngrx-toolkit';
import {inject} from '@angular/core';
import {AuthStore, handleResponse} from '@dv/shared/angular';
import type {
    WorkTimeControllingServiceGetDaysForAngestellteRequestParams,
    WorkTimeControllingServiceSaveManualEditRequestParams,
} from '@dv/shared/backend/api/work-time-controlling.service';
import {WorkTimeControllingService} from '@dv/shared/backend/api/work-time-controlling.service';
import type {JaxWorkTimeControlling} from '@dv/shared/backend/model/jax-work-time-controlling';
import {checkPresent, Displayable, DvbRestUtil} from '@dv/shared/code';
import {patchState, signalStore, withHooks, withMethods} from '@ngrx/signals';
import {setAllEntities} from '@ngrx/signals/entities';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import type moment from 'moment';
import {concatMap, pipe, switchMap, tap} from 'rxjs';
import {withWorkTimeControllingTable} from '../store/work-time-controlling-table.store';
import {createWorkTimeControllingTableData} from '../work-time-controlling-table/work-time-controlling-table.models';

export const MandantWorkTimeControllingStore = signalStore(
    withDevtools('workTimeControlling'),
    withWorkTimeControllingTable(),
    withMethods((
        store,
        service: WorkTimeControllingService = inject(WorkTimeControllingService),
        authStore: AuthStore = inject(AuthStore),
    ) => ({
        load: rxMethod<moment.Moment>(pipe(
            tap(() => patchState(store, {isLoading: true})),
            switchMap(selectedMonth => service.getAll$({
                    workTimeControlling: {
                        stichtag: DvbRestUtil.momentToLocalDateChecked(selectedMonth),
                    },
                }).pipe(
                    handleResponse({
                        next: (data: JaxWorkTimeControlling) => {
                            const wtcData =
                                createWorkTimeControllingTableData(data.angestellteWorkDays, authStore, selectedMonth);
                            const kinderOrte: Displayable[] = data.kinderOrte.map(kinderOrt =>
                                Displayable.apiResponseTransformer(kinderOrt));
                            const kinderOrtFraktionen: Displayable[] = data.kinderOrtFraktionen.map(fraktion =>
                                Displayable.apiResponseTransformer(fraktion));
                            patchState(store,
                                setAllEntities(wtcData),
                                setAllEntities(kinderOrte, {collection: 'kinderOrte'}),
                                setAllEntities(kinderOrtFraktionen, {collection: 'kinderOrtFraktionen'}));
                        },
                        finalize: () => patchState(store, {isLoading: false}),
                    }),
                ),
            ),
        )),
    })),
    withMethods((
        store,
        service: WorkTimeControllingService = inject(WorkTimeControllingService),
    ) => ({
        confirmEdit: rxMethod<WorkTimeControllingServiceSaveManualEditRequestParams>(pipe(
            tap(() => patchState(store, {isLoading: true})),
            concatMap(payload => service.saveManualEdit$(payload).pipe(
                handleResponse({
                    next: () => {
                        const date = checkPresent(payload.jaxAngestellteEditDay).date;
                        const params: WorkTimeControllingServiceGetDaysForAngestellteRequestParams = {
                            angestellteId: payload.angestellteId,
                            angestellteIdMatrix: {
                                stichtag: date,
                            },
                        };
                        store.loadDaysForMandant(params);
                    },
                    finalize: () => patchState(store, {isLoading: false}),
                })),
            ),
        )),
    })),
    withHooks({
        onInit(store) {
            store.load(store.selectedMonth);
        },
    }),
);
