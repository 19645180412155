@if (symbol(); as value) {
    <dv-pensum-icon
        [value]="value"
        [tooltip]="title()"
        [tooltipTranslated]="true"
        [inverted]="invertedSymbol()">
    </dv-pensum-icon>
}

@if (!symbol()) {
    <i class="fa"
       [ngClass]="iconClass()"
       [tooltip]="title()"
       container="body">
    </i>
}
