<ng-container *transloco="let t">
    <h2>{{ t(titleKey()) }}</h2>
    <form (ngSubmit)="submitForm(workTimeModelForm)" name="workTimeModelForm" #workTimeModelForm="ngForm">
        <div class="row">
            <div class="col-xs-6">
                <input type="text"
                       class="form-control"
                       name="name"
                       [(ngModel)]="state().name"
                       [maxlength]="255"
                       [placeholder]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NAME')"
                       [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NAME')"
                       [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NAME')">
            </div>

            <div class="col-xs-6">
                @if (isUpdateMode()) {
                    <span class="form-linker">{{ state().weeklyMinutes() | dvMinutesFormat }}</span>
                } @else {
                    <input type="text"
                           class="form-control"
                           name="weeklyMinutes"
                           dvMinutesInput
                           [(ngModel)]="state().weeklyMinutes"
                           required
                           [placeholder]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.WEEKLY_HOURS')"
                           [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.WEEKLY_HOURS')"
                           [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.WEEKLY_HOURS')">
                }
            </div>
        </div>

        <div>
            <h3>{{ t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.FERIENGUTHABEN') }}</h3>
            @for (item of state().ferienKontingente(); track item.uiId; let i = $index) {
                <div class="row"
                     role="group"
                     [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.FERIENGUTHABEN_AB', {age: i === 0 ? 0 : item.age})">
                    <div class="col-xs-6 col-sm-3">
                        @let txt = t(i === 0 ?
                            'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.AGE_0' :
                            'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.AGE');
                        <input type="number"
                               [name]="'age_' + i"
                               [disabled]="i === 0"
                               class="form-control"
                               [(ngModel)]="item.age"
                               [placeholder]="txt"
                               [tooltip]="txt"
                               required
                               min="0"
                               [attr.aria-label]="txt">
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <input type="number"
                               [name]="'ferienanspruch_' + i"
                               class="form-control"
                               [(ngModel)]="item.ferienanspruch"
                               [placeholder]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.FERIENANSPRUCH')"
                               [tooltip]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.FERIENANSPRUCH')"
                               required
                               min="0"
                               [attr.aria-label]="t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.FERIENANSPRUCH')">
                    </div>
                    <div class="col-xs-12 col-sm-2">
                        <button type="button" class="btn btn-link form-linker" (click)="removeItem(item)">
                            {{ t('COMMON.LOESCHEN') }}
                        </button>
                    </div>
                </div>
            }
        </div>
        <div class="row form-row col-xs-12">
            <button type="button" class="btn btn-link floating-text" (click)="addItem()">
                {{ t('WORK_TIME_CONTROLLING.WORK_TIME_MODEL.ADD_AGE_LEVEL') }}
            </button>
        </div>

        <dv-submit-cancel-buttons [isLoading]="isLoading()" (cancel)="handleCancel()"/>
    </form>
</ng-container>
