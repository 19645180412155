<div class="row">
    <div class="col-md-7">
        <dv-crud-accordion
            [items]="store.entities()"
            [title]="'WORK_TIME_CONTROLLING.ABSENCE_TYPES.TITLE' | transloco"
            [isEmpty]="store.isEmpty()"
            [emptyText]="'WORK_TIME_CONTROLLING.ABSENCE_TYPES.NONE' | transloco"
            [createMode]="store.showCreateMode()"
            [createText]="'WORK_TIME_CONTROLLING.ABSENCE_TYPES.NEW' | transloco"
            [showDeleteDialog]="store.showDeleteDialog()"
            [entityName]="'WORK_TIME_CONTROLLING.ABSENCE_TYPES.TITLE' | transloco"
            [isLoading]="store.isLoadingOrCreating()"
            [headerTemplate]="headerTemplate"
            [editModeTemplate]="editModeTemplate"
            [createModeTemplate]="createModeTemplate"
            [readonlyModeTemplate]="readonlyModeTemplate"
            (toggleExpanded)="store.toggleExpanded($event.id)"
            (confirmDelete)="store.confirmDelete(store.deleteEntity())"
            (hideDeleteDialog)="store.hideDeleteDialog()"
            (showCreateMode)="store.enableCreateMode()">
        </dv-crud-accordion>
    </div>
</div>

<ng-template [dvCrudAccordionTemplate]="store.entities()" let-item #headerTemplate>
    <div class="full-width-block">
        <div class="row layout-row items-center">
            <div class="col-xs-8">{{ item.entity.name }}</div>
            <div class="col-xs-4 text-right">
                <span class="badge">
                    {{
                        ('WORK_TIME_CONTROLLING.ABSENCE_TYPES.' + (item.entity.paid ? 'PAID' : 'UNPAID')) | transloco
                    }}
                </span>
                @if (item.entity.deductHolidays) {
                    <span class="badge smaller-abstand-left">
                        {{ 'WORK_TIME_CONTROLLING.ABSENCE_TYPES.HOLIDAYS_TITLE' | transloco }}
                    </span>
                }
            </div>
        </div>
    </div>
</ng-template>

<ng-template [dvCrudAccordionTemplate]="store.entities()" let-item #readonlyModeTemplate>
    <dvlib-button-list>
        <button type="button"
                class="btn btn-link"
                (click)="store.setEditMode(item.entity.id)"
                transloco="COMMON.BEARBEITEN">
        </button>
        <button type="button"
                class="btn btn-link"
                (click)="store.setShowDeleteDialog(item.entity.id)"
                transloco="COMMON.LOESCHEN">
        </button>
        <dvlib-spinner [show]="item.isLoading"/>
    </dvlib-button-list>
</ng-template>

<ng-template [dvCrudAccordionTemplate]="store.entities()" let-item #editModeTemplate>
    <dv-absence-type-form
        [absenceType]="item.entity"
        [isLoading]="item.isLoading"
        (save)="store.updateType($event)"
        (cancel)="store.setReadonlyMode(item.entity.id)">
    </dv-absence-type-form>
</ng-template>

<ng-template #createModeTemplate>
    <dv-absence-type-form
        (save)="store.createType($event)"
        (cancel)="store.disableCreateMode()">
    </dv-absence-type-form>
</ng-template>
