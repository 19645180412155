/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {SearchResultEntry, SearchResultType} from '@dv/shared/code';

const iconMap: Record<Partial<SearchResultType>, string> = {
    KIND: 'fa-child',
    ANMELDUNG_KIND: 'fa-child',
    KONTAKTPERSON: 'fa-male',
    KITA: 'fa-home',
    TAGES_ELTERN: 'fa-home',
    GRUPPE: 'fa-cube',
    BETREUUNGS_PERSON: 'fa-female',
    BENUTZER: 'fa-user',
    ANGESTELLTE: 'fa-user-o',
    TARIF: 'fa-calculator',
    RECHNUNG: 'fa-file-text-o',
    BETREUUNGSFAKTOR_REGEL: 'fa-book',
    DOKUMENT_LAYOUT: 'fa-arrows',
    SCHLIESSTAGE: 'custom-icon custom-icon-palmtree',
    FIRMA: '',
    FIRMENKONTINGENT: '',
    MANDANT: '',
    ALL: '',
};

export function getTitle(entry: SearchResultEntry | null): string | undefined {
    if (!entry || entry.entity === 'ALL') {
        return undefined;
    }

    if (entry.entity === 'FIRMA') {
        return 'COMMON.PLATZART.SINGULAR';
    }

    if (entry.entity === 'SCHLIESSTAGE') {
        return 'COMMON.SCHLIESSTAGE.PLURAL';
    }

    return `COMMON.${entry.entity}.SINGULAR`;
}

export function getIconClass(entry: SearchResultEntry | null): string | undefined {
    if (!entry) {
        return undefined;
    }

    return iconMap[entry.entity] ?? undefined;
}

