<div class="header">
    <div class="row header-row">
        <div class="col-xs-12 header-box">
            <h1 [tooltip]="'WORK_TIME_CONTROLLING.TITLE' | transloco" transloco="WORK_TIME_CONTROLLING.TITLE"></h1>
            <div class="header-dropdown btn-group" dropdown>
                <button type="button"
                        class="btn btn-link btn-link-dropdown dropdown-toggle floating-text"
                        [attr.aria-label]="'COMMON.MENU' | transloco"
                        dropdownToggle>
                    <i class="fa fa-chevron-down"></i>
                </button>
                <ul class="dropdown-menu" *dropdownMenu>
					@for (dropdownState of dropdownStates; track dropdownState.name) {
						<li>
                            <a [transloco]="dropdownState.data.subtitle"
							   [uiSref]="dropdownState.name!">
							</a>
						</li>
					}
                </ul>
            </div>

            <nav>
                <ul class="list-inline header-breadcrumb">
                    @for (state of states; track state.name) {
                        <li uiSrefActiveEq="active">
                            <a [uiSref]="state.name"
                               [transloco]="state.data.subtitle">
                            </a>
                        </li>
                    }
                </ul>
            </nav>
        </div>
    </div>
</div>

<ui-view></ui-view>
