/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Directive} from '@angular/core';
import type {SearchResultEntry} from '@dv/shared/code';
import {TypeaheadTemplateMethods} from 'ngx-bootstrap/typeahead';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';

@Directive({
    selector: 'ng-template[dvSearchEntityTemplate]',
    standalone: true,
})
export class SearchEntitySearchResultDirective {

    public static ngTemplateContextGuard(
        _: SearchEntitySearchResultDirective,
        ctx: unknown,
    ): ctx is SearchEntityResultTemplateContext {
        return true;
    }
}

export interface SearchEntityTypeaheadMatch extends TypeaheadMatch {
    readonly item: SearchResultEntry;
}

export interface SearchEntityResultTemplateContext {
    readonly $implicit: TypeaheadTemplateMethods;
    readonly matches: SearchEntityTypeaheadMatch[];
    readonly query: string | string[];
}
