<div class="row">
    <div class="col-md-7">
        <dv-crud-accordion
            [items]="items()"
            [title]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.TITLE' | transloco"
            [isEmpty]="isEmpty()"
            [emptyText]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NONE' | transloco"
            [createMode]="createMode()"
            [createText]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NEW' | transloco"
            [showDeleteDialog]="showDeleteDialog()"
            [entityName]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.SINGULAR' | transloco"
            [isLoading]="isLoading()"
            [headerTemplate]="headerTemplate"
            [editModeTemplate]="editModeTemplate"
            [createModeTemplate]="createModeTemplate"
            [readonlyModeTemplate]="readonlyModeTemplate"
            (toggleExpanded)="store.toggleExpanded($event.id)"
            (confirmDelete)="store.confirmDelete(store.deleteEntity())"
            (hideDeleteDialog)="store.hideDeleteDialog()"
            (showCreateMode)="store.enableCreateMode()">
        </dv-crud-accordion>
    </div>
</div>

<ng-template [dvCrudAccordionTemplate]="items()" let-item #headerTemplate>
    <div class="full-width-block">
        <div class="row layout-row items-center">
            <div class="col-xs-8">{{ item.entity.name }}</div>
            <div class="col-xs-2 text-right">
                @if (item.entity.timestampGeloescht) {
                    <span class="badge">
                        {{ 'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.SOFT_DELETED' | transloco }}
                    </span>
                }
                @if (item.entity.mandantDefault) {
                    <span class="badge">
                        {{ 'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.MANDANT_DEFAULT' | transloco }}
                    </span>
                }
            </div>
            <div class="col-xs-2 text-right">
                {{ item.entity.weeklyMinutes | dvMinutesFormat }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template [dvCrudAccordionTemplate]="items()" let-item #readonlyModeTemplate>
    <dvlib-button-list>
        <button type="button"
                class="btn btn-link"
                (click)="store.setEditMode(item.entity.id)"
                transloco="COMMON.BEARBEITEN">
        </button>
        @if (item.entity.timestampGeloescht) {
            <button type="button"
                    class="btn btn-link"
                    (click)="store.restore(item)"
                    transloco="WORK_TIME_CONTROLLING.WORK_TIME_MODEL.RESTORE">
            </button>
        } @else {
            <button type="button"
                    class="btn btn-link"
                    (click)="store.softDelete(item)"
                    transloco="WORK_TIME_CONTROLLING.WORK_TIME_MODEL.SOFT_DELETE">
            </button>
        }
        <button type="button"
                class="btn btn-link"
                (click)="store.setShowDeleteDialog(item.entity.id)"
                transloco="COMMON.LOESCHEN">
        </button>
        <dvlib-spinner [show]="item.isLoading"/>
    </dvlib-button-list>
</ng-template>

<ng-template [dvCrudAccordionTemplate]="items()" let-item #editModeTemplate>
    <dv-work-time-model-form
        [workTimeModel]="item.entity"
        [isLoading]="item.isLoading"
        (update)="store.updateModel($event)"
        (cancel)="store.setReadonlyMode(item.entity.id)">
    </dv-work-time-model-form>
</ng-template>

<ng-template #createModeTemplate>
    <dv-work-time-model-form
        (create)="store.createModel($event)"
        (cancel)="store.disableCreateMode()">
    </dv-work-time-model-form>
</ng-template>
