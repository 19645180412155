import {ChangeDetectionStrategy, Component, computed, inject, input, output, signal} from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {MinutesInputDirective, SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {EntityId} from '@dv/shared/backend/model/entity-id';
import {JaxCreateWorkTimeModel} from '@dv/shared/backend/model/jax-create-work-time-model';
import {JaxReadWorkTimeModel} from '@dv/shared/backend/model/jax-read-work-time-model';
import {JaxUpdateWorkTimeModel} from '@dv/shared/backend/model/jax-update-work-time-model';
import {JaxWorkTimeModelFerienKontingent} from '@dv/shared/backend/model/jax-work-time-model-ferien-kontingent';
import {checkPresent, hasOwnPropertyGuarded, isPresent} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import type {FerienKontingentModel} from './ferien-kontingent-model';
import {fromFerienKontingentModel, toFerienKontingentModel} from './ferien-kontingent-model';
import {DvMinutesFormatPipe} from '@dv/shared/angular';

@Component({
    selector: 'dv-work-time-model-form',
    standalone: true,
    imports: [
        SubmitCancelButtonsComponent,
        TranslocoModule,
        TooltipModule,
        FormsModule,
        MinutesInputDirective,
        DvMinutesFormatPipe,
    ],
    templateUrl: './work-time-model-form.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeModelFormComponent {
    public workTimeModel = input<JaxReadWorkTimeModel | JaxCreateWorkTimeModel>();
    public isLoading = input<boolean>(false);

    public readonly create = output<JaxCreateWorkTimeModel>();
    public readonly update = output<JaxUpdateWorkTimeModel>();
    public readonly cancel = output<void>();

    private readonly errorService = inject(ErrorService);

    private entityId = computed(() => {
        const model = this.workTimeModel();

        return isPresent(model) && hasOwnPropertyGuarded(model, 'id') ? model.id as EntityId : undefined;
    });

    public isUpdateMode = computed(() => isPresent(this.entityId()));

    public titleKey = computed(() => this.isUpdateMode() ?
        'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.EDIT' :
        'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NEW');

    public state = computed(() => {
        const kontingente: JaxWorkTimeModelFerienKontingent[] = [...this.workTimeModel()?.ferienKontingente ?? []];
        kontingente.sort((a, b) => a.age - b.age);
        const ferienKontingenteCopy: FerienKontingentModel[] = kontingente.map(toFerienKontingentModel);

        return {
            name: signal(this.workTimeModel()?.name),
            weeklyMinutes: signal(this.workTimeModel()?.weeklyMinutes),
            ferienKontingente: signal(ferienKontingenteCopy),
        };
    });

    public removeItem(kontingent: FerienKontingentModel): void {
        this.state().ferienKontingente.update(value => value.filter(k => k !== kontingent));
    }

    public addItem(): void {
        this.state().ferienKontingente.update(value => [...value, toFerienKontingentModel()]);
    }

    public submitForm(workTimeModelForm: NgForm): void {
        this.errorService.clearAll();
        const ferienKontingente = this.state().ferienKontingente();
        const hasDuplicateAges = ferienKontingente
            .some(item => ferienKontingente.filter(k => k.age === item.age).length > 1);

        if (hasDuplicateAges) {
            this.errorService.addValidationError('ERRORS.ERR_AGE_SHOULD_NOT_SAME');

            return;
        }

        if (!workTimeModelForm.valid) {
            this.errorService.addValidationError('ERRORS.ERR_INCOMPLETE_FORM');

            return;
        }

        if (this.isUpdateMode()) {
            this.update.emit({
                id: checkPresent(this.entityId()),
                name: this.state().name(),
                ferienKontingente: ferienKontingente.map(fromFerienKontingentModel),
            });

            return;
        }

        this.create.emit({
            name: this.state().name(),
            weeklyMinutes: checkPresent(this.state().weeklyMinutes()),
            ferienKontingente: ferienKontingente.map(fromFerienKontingentModel),
        });
    }

    public handleCancel(): void {
        this.errorService.clearAll();
        this.cancel.emit();
    }
}
