/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {PensumIconComponent} from '@dv/kitadmin/ui';
import {SearchResultEntry} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {getIconClass, getTitle} from './search-result-icon-helpers';

@Component({
    selector: 'dv-search-result-icon',
    standalone: true,
    imports: [TooltipModule, NgClass, PensumIconComponent],
    templateUrl: './search-result-icon.component.html',
    styles: `
        :host {
            display: inline-block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'fa-fw',
    },
})
export class SearchResultIconComponent {

    private readonly translator = inject(Translator);

    public entry = input.required<SearchResultEntry>();

    public title = computed(() => {
        const title = getTitle(this.entry());

        return title ? this.translator.instant(title) : undefined;
    });
    public iconClass = computed(() => getIconClass(this.entry()));
    public symbol = computed(() => this.entry().symbol);
    public invertedSymbol = computed(() => this.entry().entity !== 'FIRMENKONTINGENT');
}
