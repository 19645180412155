<div class="selected-entity" *transloco="let t">
    <div class="entity truncate">
        <dv-search-result-icon [entry]="searchResultEntry"/>
        <span>
            {{ t('SEARCH.ENTITY_TITLE_MF', {text: searchResultEntry.text, entity: searchResultEntry.text}) }}
        </span>
    </div>
    <div class="edit">
        @if (showRemoveButton) {
            <button class="btn btn-link"
                    type="button"
                    (click)="remove.emit(searchResultEntry)"
                    [disabled]="isDisabled">
                {{ t('COMMON.LOESCHEN') }}
            </button>
        }
    </div>
</div>
