import {withDevtools} from '@angular-architects/ngrx-toolkit';
import {computed, inject} from '@angular/core';
import type {ItemWithEntity} from '@dv/kitadmin/ui';
import {selectId, withAccordionFunctionality} from '@dv/kitadmin/ui';
import {handleResponse, idFromLocation} from '@dv/shared/angular';
import {WorkTimeModelService} from '@dv/shared/backend/api/work-time-model.service';
import type {JaxCreateWorkTimeModel} from '@dv/shared/backend/model/jax-create-work-time-model';
import type {JaxReadWorkTimeModel} from '@dv/shared/backend/model/jax-read-work-time-model';
import type {JaxUpdateWorkTimeModel} from '@dv/shared/backend/model/jax-update-work-time-model';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted, DvbRestUtil} from '@dv/shared/code';
import {patchState, signalStore, withComputed, withHooks, withMethods, withState} from '@ngrx/signals';
import {removeEntity, updateEntity} from '@ngrx/signals/entities';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import moment from 'moment';
import {concatMap, pipe, switchMap, tap} from 'rxjs';

type ModelsState = {
    isCreating: boolean;
    createdModelId: string;
};

const initialState: ModelsState = {
    isCreating: false,
    createdModelId: '',
};

export const WorkTimeModelsStore = signalStore(
    withDevtools('workTimeModels'),
    withState(initialState),
    withAccordionFunctionality<Persisted<JaxReadWorkTimeModel>>(),
    withComputed(store => ({
        isLoadingOrCreating: computed<boolean>(() => {
            const loading = store.isLoading();
            const creating = store.isCreating();

            return loading || creating;
        }),
    })),
    withMethods((
        store,
        service = inject(WorkTimeModelService),
    ) => ({
        load: rxMethod<void>(pipe(
            tap(() => store.startLoading()),
            switchMap(() => service.getAll$().pipe(
                handleResponse({
                    next: data => store.initWithEntities(data.models.map(checkPersisted)),
                    finalize: () => store.finishLoading(),
                }),
            )),
        )),
        createModel: rxMethod<JaxCreateWorkTimeModel>(pipe(
            tap(() => patchState(store, {isCreating: true})),
            concatMap(jaxCreateWorkTimeModel => service.create$({jaxCreateWorkTimeModel}, 'response')
                .pipe(
                    handleResponse({
                        next: response => {
                            const id = idFromLocation(response);
                            patchState(store, {createdModelId: id});
                            store.add({id, ...jaxCreateWorkTimeModel});
                            store.disableCreateMode();
                        },
                        finalize: () => patchState(store, {isCreating: false}),
                    }),
                ),
            ),
        )),
        updateModel: rxMethod<JaxUpdateWorkTimeModel>(pipe(
            tap(model => store.setItemIsLoadingTrue(model.id)),
            concatMap(model =>
                service.update$({id: model.id, jaxUpdateWorkTimeModel: model}).pipe(
                    handleResponse({
                        next: () => store.update({...store.entityMap()[model.id].entity, ...model}),
                        finalize: () => store.setItemIsLoadingFalse(model.id),
                    }),
                )),
        )),
        softDelete: rxMethod<ItemWithEntity<Persisted<JaxReadWorkTimeModel>>>(pipe(
            tap(item => store.setItemIsLoadingTrue(item.entity.id)),
            concatMap(item => service.softdelete$({id: item.entity.id}).pipe(
                handleResponse({
                    next: () => {
                        patchState(store, updateEntity({
                            id: item.entity.id,
                            changes: {
                                entity: Object.assign(item.entity, {
                                    timestampGeloescht: DvbRestUtil.momentToLocalDateTime(moment()),
                                }),
                            },
                        }, {selectId}));
                    },
                    finalize: () => store.setItemIsLoadingFalse(item.entity.id),
                }),
            )),
        )),
        restore: rxMethod<ItemWithEntity<Persisted<JaxReadWorkTimeModel>>>(pipe(
            tap(item => store.setItemIsLoadingTrue(item.entity.id)),
            concatMap(item => service.restore$({id: item.entity.id}).pipe(
                handleResponse({
                    next: () => {
                        patchState(store, updateEntity({
                            id: item.entity.id,
                            changes: {
                                entity: Object.assign(item.entity, {
                                    timestampGeloescht: null,
                                }),
                            },

                        }, {selectId}));
                    },
                    finalize: () => store.setItemIsLoadingFalse(item.entity.id),
                }),
            )),
        )),
        confirmDelete: rxMethod<string>(pipe(
            tap(() => patchState(store, {isLoading: true})),
            concatMap(id => service._delete$({id}).pipe(
                handleResponse({
                    next: () => {
                        patchState(store, removeEntity(id));
                        store.hideDeleteDialog();
                    },
                    finalize: () => patchState(store, {isLoading: false}),
                }),
            )),
        )),
    })),
    withHooks({
        onInit(store) {

            // reload whenever a new model got created
            store.load(store.createdModelId);
        },
    }),
);
