import {ChangeDetectionStrategy, Component, effect, inject, Input, type OnInit} from '@angular/core';
import {DateSwitcherComponent, SpinnerComponent} from '@dv/shared/angular';
import {isPresent} from '@dv/shared/code';
import type {UiOnParamsChanged} from '@uirouter/angular';
import {Transition} from '@uirouter/core';
import {WorkTimeControllingTableComponent} from '../work-time-controlling-table/work-time-controlling-table.component';
import {MandantWorkTimeControllingStore} from './mandant-work-time-controlling.store';

@Component({
    selector: 'dv-work-time-control',
    standalone: true,
    imports: [
        WorkTimeControllingTableComponent,
        DateSwitcherComponent,
        SpinnerComponent,
    ],
    templateUrl: './mandant-work-time-controlling.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    providers: [MandantWorkTimeControllingStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MandantWorkTimeControllingComponent implements OnInit, UiOnParamsChanged {
    public readonly store = inject(MandantWorkTimeControllingStore);

    // ui-router is not compatible with signal-inputs
    @Input({required: true})
    public $transition$!: Transition;

    private pathParamsInitialized = false;
    // noinspection JSUnusedLocalSymbols
    private applyPathParamsEffect = effect(() => {
        const date = this.store.selectedMonth();

        if (this.pathParamsInitialized) {
            this.$transition$.router.stateService.go('.', {date}, {location: true});
        }

        this.pathParamsInitialized = true;
    });

    public ngOnInit(): void {
        this.uiOnParamsChanged(this.$transition$.params());
    }

    public uiOnParamsChanged(newParams: { [paramName: string]: any }): void {
        if (isPresent(newParams.date)) {
            this.store.applyMonth(newParams.date);
        }
    }
}
