/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxWorkTimeModelFerienKontingent} from '@dv/shared/backend/model/jax-work-time-model-ferien-kontingent';
import {nextId} from '@dv/shared/code';

export type FerienKontingentModel = Partial<JaxWorkTimeModelFerienKontingent> & { uiId: string };

export function toFerienKontingentModel(k?: JaxWorkTimeModelFerienKontingent): FerienKontingentModel {
    return {...k, uiId: nextId()};
}

export function fromFerienKontingentModel(k: FerienKontingentModel): {
    age: number;
    ferienanspruch: number;
} {
    return {
        age: k.age ?? 0,
        ferienanspruch: k.ferienanspruch ?? 0,
    };
}
