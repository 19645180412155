import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {CrudAccordionComponent, CrudAccordionTemplateDirective} from '@dv/kitadmin/ui';
import {ButtonListComponent, SpinnerComponent} from '@dv/shared/angular';
import {TranslocoModule} from '@jsverse/transloco';
import {AbsenceTypeFormComponent} from '../absence-type-form/absence-type-form.component';
import {AbsenceTypesStore} from './absence-types.store';

@Component({
    selector: 'dv-absence-types',
    standalone: true,
    imports: [
        ButtonListComponent,
        CrudAccordionComponent,
        CrudAccordionTemplateDirective,
        SpinnerComponent,
        TranslocoModule,
        AbsenceTypeFormComponent,
    ],
    providers: [AbsenceTypesStore],
    templateUrl: './absence-types.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbsenceTypesComponent {
    public readonly store = inject(AbsenceTypesStore);
}
