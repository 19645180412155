import {NgClass} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
    output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import {FormsModule, NgForm} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {WorkTimePlannedTime, WorkTimePlannedTimeType} from '@dv/kitadmin/models';
import {SearchEntityComponent} from '@dv/kitadmin/search';
import {MinutesInputDirective, OverlayDirective} from '@dv/kitadmin/ui';
import {
    ButtonComponent,
    ButtonListComponent,
    DatepickerTextfieldComponent,
    DisplayNamePipe,
    DvTimeFormatPipe,
    ElasticTextInputDirective,
    RequirePermissionAttributeDirective,
    RequirePermissionStructuralDirective,
    ValidBisDirective,
    ValidVonDirective,
} from '@dv/shared/angular';
import {
    WorkTimeControllingServiceSaveManualEditRequestParams,
} from '@dv/shared/backend/api/work-time-controlling.service';
import {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxAngestellteStandortWorkDay} from '@dv/shared/backend/model/jax-angestellte-standort-work-day';
import {JaxPlannedAbsenceTermin} from '@dv/shared/backend/model/jax-planned-absence-termin';
import type {JaxTimeRange} from '@dv/shared/backend/model/jax-time-range';
import type {JaxWorkTimeControllingAbsence} from '@dv/shared/backend/model/jax-work-time-controlling-absence';
import {JaxWorkTimeControllingAbsenceType} from '@dv/shared/backend/model/jax-work-time-controlling-absence-type';
import {JaxWorkTimeControllingTime} from '@dv/shared/backend/model/jax-work-time-controlling-time';
import {
    checkPresent,
    Displayable,
    DvbDateUtil,
    DvbRestUtil,
    isNullish,
    nextId,
    Nullish,
    SearchResultEntry,
    TimeRange,
    TimeRangeUtil,
} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {EntityMap} from '@ngrx/signals/entities';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {
    AngestellteStandortWorkDay,
    WorkTimeControllingAbsence,
    WorkTimeControllingHoursDaily,
} from '../work-time-controlling-table/work-time-controlling-table.models';

type ActualTimeFormModel = {
    uiId: string;
    editPermitted: boolean;
    overlayOpen: WritableSignal<boolean>;
    kinderOrt: SearchResultEntry | undefined;
    kinderOrtFraktion: SearchResultEntry | undefined;
    timeRange: Partial<JaxTimeRange>;
};

type AbsenceFormModel = {
    uiId: string;
    editPermitted: boolean;
    overlayOpen: WritableSignal<boolean>;
    kinderOrt: SearchResultEntry | undefined;
    absenceId: EntityId | undefined;
    absenceTypeId: EntityId | undefined;
    timeRange: Partial<JaxTimeRange>;
    minutes: number | undefined;
};

@Component({
    selector: 'dv-work-time-controlling-day-detail',
    standalone: true,
    imports: [
        ElasticTextInputDirective,
        FormsModule,
        TooltipModule,
        ButtonComponent,
        TranslocoModule,
        RequirePermissionStructuralDirective,
        ButtonListComponent,
        MinutesInputDirective,
        DisplayNamePipe,
        NgClass,
        DvTimeFormatPipe,
        ValidVonDirective,
        ValidBisDirective,
        RequirePermissionAttributeDirective,
        OverlayDirective,
        SearchEntityComponent,
        DatepickerTextfieldComponent,
    ],
    templateUrl: './work-time-controlling-day-detail.component.html',
    styleUrl: './work-time-controlling-day-detail.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeControllingDayDetailComponent {

    public dailyHours = input.required<WorkTimeControllingHoursDaily>();
    public absenceTypes = input<JaxWorkTimeControllingAbsenceType[]>([]);
    public absenceTermine = input<JaxPlannedAbsenceTermin[]>([]);
    public kinderOrteById = input.required<EntityMap<Displayable>>();
    public kinderOrtFraktionenById = input.required<EntityMap<Displayable>>();
    public angestellteId = input.required<string>();
    public balanceCorrection = input<number | null>();
    public isBalanceCorrectionVisible = signal<boolean>(false);
    public balanceCorrectionHolidays = input<number | null>();
    public isBalanceCorrectionHolidaysVisible = signal<boolean>(false);

    /**
     * When true, displays a date input and an abort button instead of the reset button.
     */
    public forNewDay = input<boolean>(false);
    public availableDates = input<BackendLocalDate[]>([]);

    public readonly abort = output<void>();

    public selectedDate = computed(() => DvbDateUtil.toMoment(this.state().date()));

    public readonly save = output<WorkTimeControllingServiceSaveManualEditRequestParams>();

    private errorService = inject(ErrorService);

    private plannedTimeRanges: Signal<WorkTimePlannedTime[]> = computed(() => {
        const kinderOrteById = this.kinderOrteById();
        const kinderOrtFraktionenById = this.kinderOrtFraktionenById();

        return this.dailyHours().standortWorkDays
            .reduce((acc: WorkTimePlannedTime[], workDay: JaxAngestellteStandortWorkDay) => [
                    ...acc,
                    ...workDay.plannedTimeRanges?.map(
                        (timeRange: JaxTimeRange) => {
                            const kinderOrtName = workDay.kinderOrtId ?
                                kinderOrteById[workDay.kinderOrtId]?.getDisplayName() :
                                '';
                            const kinderOrtFraktionName = workDay.kinderOrtFraktionId ?
                                kinderOrtFraktionenById[workDay.kinderOrtFraktionId]?.getDisplayName() :
                                '';

                            return new WorkTimePlannedTime(
                                kinderOrtName ? kinderOrtName : kinderOrtFraktionName ?? '',
                                WorkTimePlannedTimeType.WORK,
                                TimeRange.apiResponseTransformer(timeRange),
                            );
                        },
                    ) ?? [],
                ],
                [],
            );
    });

    private plannedAbsenceTermine: Signal<WorkTimePlannedTime[]> = computed(() =>
        this.dailyHours().absenceTermine.map((absenceTermin: JaxPlannedAbsenceTermin) =>
            new WorkTimePlannedTime(
                absenceTermin.title ?? '',
                WorkTimePlannedTimeType.TERMIN,
                TimeRange.apiResponseTransformer(absenceTermin.timeRange),
            ),
        ));

    public plannedTimes: Signal<WorkTimePlannedTime[]> = computed(() => {
        const plannedTimeRanges: WorkTimePlannedTime[] = this.plannedTimeRanges();
        const plannedAbsenceTermine: WorkTimePlannedTime[] = this.plannedAbsenceTermine();

        return [...plannedTimeRanges, ...plannedAbsenceTermine].sort((a, b) => {
            return TimeRangeUtil.TIME_RANGE_COMPARATOR(a.timeRange, b.timeRange);
        });
    });

    public state = computed(() => {
        const date = signal(this.dailyHours().date);

        return {
            date,
            note: signal(this.dailyHours().note),
            actualTimes: signal(this.toActualTimeRangeModels(this.dailyHours().standortWorkDays)),
            absences: signal(this.toAbsenceModels(this.dailyHours().absences)),
            balanceCorrection: signal(this.dailyHours().balanceCorrection),
            isBalanceCorrectionVisible: signal(this.isBalanceCorrectionVisible()
                || this.dailyHours().balanceCorrection !== null),
            balanceCorrectionHolidays: signal(this.dailyHours().balanceCorrectionHolidays),
            isBalanceCorrectionHolidaysVisible: signal(this.isBalanceCorrectionHolidaysVisible()
                || this.dailyHours().balanceCorrectionHolidays !== null),
            dateMoment: computed(() => DvbDateUtil.toMoment(date())),
        };
    });

    public addWorkDay(): void {
        this.state().actualTimes.update((workDays: ActualTimeFormModel[]) => [
            ...workDays,
            {
                uiId: nextId(),
                editPermitted: true,
                overlayOpen: signal(false),
                kinderOrt: undefined,
                kinderOrtFraktion: undefined,
                timeRange: {von: undefined, bis: undefined},
            },
        ]);
    }

    public addAbsence(): void {
        this.state().absences.update((absences: AbsenceFormModel[]) => [
            ...absences,
            {
                uiId: nextId(),
                editPermitted: true,
                overlayOpen: signal(false),
                kinderOrt: undefined,
                absenceId: undefined,
                absenceTypeId: undefined,
                timeRange: {von: undefined, bis: undefined},
                minutes: undefined,
            },
        ]);
    }

    public removeTimeRange(toRemove: ActualTimeFormModel): void {
        this.state().actualTimes.update(models => models.filter(m => m.uiId !== toRemove.uiId));
    }

    public removeAbsence(toRemove: AbsenceFormModel): void {
        this.state().absences.update(models => models.filter(m => m.uiId !== toRemove.uiId));
    }

    public updateAbsenceMinutes(absence: AbsenceFormModel): void {
        if (isNullish(absence.timeRange.von) || isNullish(absence.timeRange.bis)) {
            absence.minutes = undefined;

            return;
        }

        absence.minutes = DvbDateUtil.getTimeDiff(
            DvbRestUtil.localeHHMMTimeToMomentChecked(absence.timeRange.von),
            DvbRestUtil.localeHHMMTimeToMomentChecked(absence.timeRange.bis));
    }

    public removeCorrection(): void {
        this.state().balanceCorrection.set(null);
        this.state().isBalanceCorrectionVisible.set(false);
    }

    public removeCorrectionHolidays(): void {
        this.state().balanceCorrectionHolidays.set(null);
        this.state().isBalanceCorrectionHolidaysVisible.set(false);
    }

    public resetChanges(): void {
        this.state().actualTimes.set(this.toActualTimeRangeModels(this.dailyHours().standortWorkDays));
        this.state().absences.set(this.toAbsenceModels(this.dailyHours().absences));
        this.state().balanceCorrection.set(this.dailyHours().balanceCorrection);
        this.state().balanceCorrectionHolidays.set(this.dailyHours().balanceCorrectionHolidays);
        this.state().note.set(this.dailyHours().note);
    }

    public submit($event: Event, detailForm: NgForm): void {
        this.errorService.clearAll();

        const isFormValid = detailForm.valid ?? false;
        this.errorService.handleValidationError(isFormValid, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (!isFormValid) {
            detailForm.onSubmit($event);

            return;
        }

        let balanceCorrection: number | Nullish = this.state().balanceCorrection();
        if (isNullish(balanceCorrection) || balanceCorrection === 0) {
            balanceCorrection = undefined;
        }
        let balanceCorrectionHolidays: number | Nullish = this.state().balanceCorrectionHolidays();
        if (isNullish(balanceCorrectionHolidays) || balanceCorrectionHolidays === 0) {
            balanceCorrectionHolidays = undefined;
        }

        const standortTimes: JaxWorkTimeControllingTime[] = this.state().actualTimes().map(a => ({
            kinderOrtId: a.kinderOrt?.id,
            kinderOrtFraktionId: a.kinderOrtFraktion?.id,
            actualTime: {von: checkPresent(a.timeRange.von), bis: checkPresent(a.timeRange.bis)},
        }));

        const absences: JaxWorkTimeControllingAbsence[] = this.state().absences().map(a => ({
            angestellteId: this.angestellteId(),
            absenceId: a.absenceId,
            absenceTypeId: checkPresent(a.absenceTypeId),
            kinderOrtId: a.kinderOrt?.id,
            von: a.timeRange.von,
            bis: a.timeRange.bis,
            minutes: a.minutes,
        }));

        this.save.emit({
            angestellteId: this.angestellteId(),
            jaxAngestellteEditDay: {
                angestellteId: this.angestellteId(),
                date: this.state().date(),
                note: this.state().note() ?? undefined,
                standortTimes,
                absences,
                balanceCorrection,
                balanceCorrectionHolidays,
            },
        });
    }

    private toActualTimeRangeModels(standortWorkDays: AngestellteStandortWorkDay[]): ActualTimeFormModel[] {
        return standortWorkDays.flatMap((swd): ActualTimeFormModel[] => {
            return (swd.actualTimeRanges ?? [])
                .map((a): ActualTimeFormModel => {
                    const kinderOrt = swd.kinderOrtId ?
                        new SearchResultEntry('KITA',
                            swd.kinderOrtId,
                            this.kinderOrteById()[swd.kinderOrtId].displayName) :
                        undefined;

                    const kinderOrtFraktion = swd.kinderOrtFraktionId ?
                        new SearchResultEntry('GRUPPE',
                            swd.kinderOrtFraktionId,
                            this.kinderOrtFraktionenById()[swd.kinderOrtFraktionId].getDisplayName()) :
                        undefined;

                    return {
                        uiId: nextId(),
                        editPermitted: swd.editPermitted,
                        overlayOpen: signal(false),
                        kinderOrt,
                        kinderOrtFraktion,
                        timeRange: {von: a.von, bis: a.bis},
                    };
                });
        });
    }

    private toAbsenceModels(absence: WorkTimeControllingAbsence[]): AbsenceFormModel[] {
        return absence.map((a): AbsenceFormModel => {
            const kinderOrt = a.kinderOrtId ?
                new SearchResultEntry('KITA',
                    a.kinderOrtId,
                    this.kinderOrteById()[a.kinderOrtId].getDisplayName()) :
                undefined;

            return {
                uiId: nextId(),
                editPermitted: a.editPermitted,
                overlayOpen: signal(false),
                kinderOrt,
                absenceId: a.absenceId,
                absenceTypeId: a.absenceTypeId,
                timeRange: {von: a.von, bis: a.bis},
                minutes: a.minutes,
            };
        });
    }
}
